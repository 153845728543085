@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
body {
  @apply font-sans;
  font-family: "Lato", serif;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.button14,
.button16,
.button18 {
  font-family: "DM Sans";
  font-weight: 700;
  font-style: normal;
  line-height: 30px;
}

.bodyxl,
.bodyl,
.bodym,
.bodys {
  font-family: "Lato", serif;
  font-style: normal;
  font-weight: regular;
}

.bodyxlbold,
.bodylbold,
.bodymbold,
.bodysbold {
  font-weight: bold;
}

.bodyxl,
.bodyxlbold {
  font-size: 18px;
  line-height: 31px;
}
.bodyl,
.bodylbold {
  font-size: 16px;
  line-height: 28px;
}
.bodym,
.bodymbold {
  font-size: 14px;
  line-height: 22px;
}
.bodys,
.bodysbold {
  font-size: 12px;
  line-height: 22px;
}

.h1 {
  font-size: 96px;
  line-height: auto;
  letter-spacing: -1px;
}

.h2 {
  font-size: 60px;
  line-height: auto;
  letter-spacing: -1px;
}
.h3 {
  font-size: 48px;
  line-height: auto;
  letter-spacing: -0.5px;
}
.h4 {
  font-size: 34px;
  line-height: auto;
  letter-spacing: -0.25px;
}
.h5 {
  font-size: 24px;
  line-height: auto;
  letter-spacing: 0px;
}
.h6 {
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0px;
}

.button14 {
  font-size: 14px;
}
.button16 {
  font-size: 16px;
}
.button18 {
  font-size: 18px;
}
